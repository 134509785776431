import React from 'react'
import Layout from '../hoc/Layout/Layout'
import vocabularyData from '../../data/vocabulary/vocabulary.json'
import Vocabulary from '../components/Pages/Vocabulary/Vocabulary'
import { stringToVocabularyUrl } from '../utils/utils'

const VocabularyItem = () => {
  const itemUrl =
    typeof window !== `undefined` ? window.location.href.split('/').pop() : ''
  const vocabularyItem = Object.keys(vocabularyData).filter(
    key => stringToVocabularyUrl(key) === itemUrl
  )[0]
  const data = {
    item: vocabularyItem,
    definition: vocabularyData[vocabularyItem]
  }

  let term = data.item ? data.item : ''
  term = term.replace(/,/g, '')
  term = term.replace(/[^\w\s-]/g, '')

  let definition = data.definition ? data.definition : ''
  definition = definition.split(' ').splice(0, 5)

  const title =
    term +
    ' | PMP Vocabulary | PMP ' +
    (term.split(' ').length > 1 ? 'Terms' : 'Glossary')
  const keywords = [term, 'PMP Vocabulary', 'PMP Glossary', 'PMP Terms']
  const description = `What is ${term} - ${definition.join(' ')}...`

  return (
    <Layout>
      <Vocabulary
        data={data}
        termTitle={title}
        termKeywords={keywords}
        termDescription={description}
      />
    </Layout>
  )
}

export default VocabularyItem
